import * as React from 'react';
import { Divider } from 'wix-ui-tpa/Divider';
import s from './OfferingInfo.st.css';
import { IOfferingViewModel } from '../../domain/offering-view-model-factory';
import { OfferingName } from './OfferingName/OfferingName';
import { OfferingTagLine } from './OfferingTagLine/OfferingTagLine';
import { OfferingDetails } from './OfferingDetails/OfferingDetails';
import { OfferingCTA } from './OfferingCTA/OfferingCTA';
import {
  BiLoggerProps,
  withBiLoggerContext,
} from '../context/bi-logger-context';
import { WIDGET_BI_REFERRAL } from '../../adapters/reporting/bi-logger/bi.const';
import { OfferingIntent } from '../../platform/navigation/navigation.const';
import {
  OfferingCallbacksProps,
  withOfferingCallbacksContext,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../context/running-environment-context';
import { OfferingListLayoutOptions } from '../../../Shared/appKeys/SettingsKeys';

interface OfferingInfoProps {
  offeringViewModel: IOfferingViewModel;
}

class OfferingInfo extends React.PureComponent<
  OfferingInfoProps &
    BiLoggerProps &
    OfferingCallbacksProps &
    RunningEnvironmentProps
> {
  constructor(props) {
    super(props);
    this.actionButtonClickHandler = this.actionButtonClickHandler.bind(this);
    this.offeringNameClickHandler = this.offeringNameClickHandler.bind(this);
  }

  actionButtonClickHandler() {
    const { offeringViewModel } = this.props;

    this.logActionButtonClicked();
    if (offeringViewModel.displayOnlyNoBookFlow) {
      this.onOfferingSelected(OfferingIntent.SHOW_DETAILS);
    } else {
      this.onOfferingSelected(OfferingIntent.BOOK_OFFERING);
    }
  }

  offeringNameClickHandler() {
    this.logOfferingNameClicked();
    this.onOfferingSelected(OfferingIntent.SHOW_DETAILS);
  }

  logOfferingNameClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_TITLE);
  }

  logActionButtonClicked() {
    this.logAction(WIDGET_BI_REFERRAL.WIDGET_BUTTON);
  }

  logAction(referralInfo): void {
    const { offeringViewModel, biLoggerDriver } = this.props;
    biLoggerDriver.sendWidgetClick(
      offeringViewModel.id,
      offeringViewModel.type,
      referralInfo,
    );
  }

  onOfferingSelected(intent: OfferingIntent) {
    const { offeringCallbacks, offeringViewModel } = this.props;
    offeringCallbacks.onOfferingSelected(offeringViewModel.id, intent);
  }

  private isStripLayout(): boolean {
    return (
      this.props.offeringViewModel.layout === OfferingListLayoutOptions.STRIP
    );
  }

  private renderHorizontalLayoutInfo() {
    const { offeringViewModel } = this.props;

    return (
      <div
        data-hook="info-container"
        {...s('horizontalLayout', {
          verticalAlignContent: offeringViewModel.verticalAlignInfoContent,
        })}
      >
        <div className={s.column}>
          <OfferingName
            data-hook="tile-title"
            onClick={this.offeringNameClickHandler}
          >
            {offeringViewModel.title}
          </OfferingName>
          {offeringViewModel.tagLine && (
            <OfferingTagLine data-hook="tile-tagline">
              {offeringViewModel.tagLine}
            </OfferingTagLine>
          )}
        </div>
        <div className={s.column}>
          {offeringViewModel.days && (
            <OfferingDetails data-hook="tile-days" ellipsis>
              {offeringViewModel.days}
            </OfferingDetails>
          )}
          {offeringViewModel.startDate && (
            <OfferingDetails data-hook="tile-start-date" ellipsis>
              {offeringViewModel.startDate}
            </OfferingDetails>
          )}
          {offeringViewModel.duration && (
            <OfferingDetails data-hook="tile-duration" ellipsis>
              {offeringViewModel.duration}
            </OfferingDetails>
          )}
          {offeringViewModel.price && (
            <OfferingDetails data-hook="tile-price" ellipsis>
              {offeringViewModel.price}
            </OfferingDetails>
          )}
        </div>
        {offeringViewModel.action.isVisible && (
          <div className={s.column}>
            <OfferingCTA
              onClick={this.actionButtonClickHandler}
              data-hook="tile-button"
              large={offeringViewModel.action.isLarge}
              secondary={offeringViewModel.action.isSecondary}
              theme={offeringViewModel.action.type}
            >
              {offeringViewModel.action.text}
            </OfferingCTA>
          </div>
        )}
      </div>
    );
  }

  private renderVerticalLayoutInfo() {
    const { offeringViewModel, runningEnvironment } = this.props;
    const { isMobile } = runningEnvironment;
    return (
      <div
        data-hook="info-container"
        {...s('verticalLayout', {
          alignment: offeringViewModel.textAlignment,
          verticalAlignContent: offeringViewModel.verticalAlignInfoContent,
          isMobile,
        })}
      >
        <OfferingName
          data-hook="tile-title"
          onClick={this.offeringNameClickHandler}
        >
          {offeringViewModel.title}
        </OfferingName>
        {offeringViewModel.tagLine && (
          <OfferingTagLine data-hook="tile-tagline">
            {offeringViewModel.tagLine}
          </OfferingTagLine>
        )}
        {offeringViewModel.dividerVisibility && (
          <Divider
            data-hook="tile-divider"
            tabIndex={-1}
            className={s.divider}
          />
        )}
        {offeringViewModel.days && (
          <OfferingDetails data-hook="tile-days">
            {offeringViewModel.days}
          </OfferingDetails>
        )}
        {offeringViewModel.startDate && (
          <OfferingDetails data-hook="tile-start-date">
            {offeringViewModel.startDate}
          </OfferingDetails>
        )}
        {offeringViewModel.duration && (
          <OfferingDetails data-hook="tile-duration">
            {offeringViewModel.duration}
          </OfferingDetails>
        )}
        {offeringViewModel.price && (
          <OfferingDetails data-hook="tile-price">
            {offeringViewModel.price}
          </OfferingDetails>
        )}
        {offeringViewModel.action.isVisible && (
          <OfferingCTA
            onClick={this.actionButtonClickHandler}
            data-hook="tile-button"
            large={offeringViewModel.action.isLarge}
            secondary={offeringViewModel.action.isSecondary}
            theme={offeringViewModel.action.type}
          >
            {offeringViewModel.action.text}
          </OfferingCTA>
        )}
      </div>
    );
  }

  render() {
    return this.isStripLayout()
      ? this.renderHorizontalLayoutInfo()
      : this.renderVerticalLayoutInfo();
  }
}

export default withRunningEnvironmentContext(
  withOfferingCallbacksContext(withBiLoggerContext(OfferingInfo)),
);
